import * as React from "react"
import Header from "../components/Header/Header";
import "./../sass/main.scss"
import Footer from "../components/Footer/Footer";
import PageDetails from "../components/PageDetails";
import {useEffect} from "react";

let lang = process.env.LANG;
let isFrench = process.env.LANG === "FR";
let isLocal = ['dev', 'development'].includes(process.env.NODE_ENV);
let fr_url = (isLocal ? "localhost:8000" : process.env.FR_URL) + "/fr/";
let en_url = (isLocal ? "localhost:8000" : process.env.EN_URL) + "/en/";
const NotFoundPage = () => {
    useEffect(() => {
        let errorMessage = document.getElementById("error_message");
        if (window.location.href.includes("/en/careers/") || window.location.href.includes("/fr/carrieres/")) {
            if (isFrench) {
                errorMessage.innerHTML = "<p>Cet avis d’offre d’emploi est maintenant fermé. Nous vous remercions de votre intérêt et vous invitons à continuer à visiter notre <a href='https://dotation-erp.international.gc.ca/fr/'>site internet</a> pour de futures opportunités d’emploi.</p>"
            } else {
                errorMessage.innerHTML = "<p>This job poster is now closed. We thank you for your interest and invite you to continue visiting <a href='https://staffing-les.international.gc.ca/en/'>our website</a> for future job opportunities.</p>";
            }
        }
    }, [])


    return (<>
        <Header
            title={isFrench ? "Page non trouvée | Connecteur d'emploi des affaires mondiales" : "Page Not Found | Global Affairs Job Connector"}
            description={isFrench ? "Page non trouvée | Connecteur d'emploi des affaires mondiales" : "Page Not Found | Global Affairs Job Connector"}
            hidden={false} lang={lang} langLink={isFrench ? en_url : fr_url} disableBreadcrumbs={true} bodyClasses={"page-404 splash"}/>


        <main property="mainContentOfPage" className={"container pb-5"} resource="#wb-main" typeof="WebPageElement">
            <h1>{isFrench ? "Page Non Trouvée" : "404 Page Not Found"}</h1>
            {isFrench ? <p id={"error_message"}>La page que vous essayez de joindre n'existe pas.</p> :
                <p id={"error_message"}>The page you are trying to reach does not exist</p>}
            <a href={"/"}>{isFrench ? "Retour à l'accueil" : "Back to homepage"}</a>
            <PageDetails/>
        </main>
        <Footer/>
    </>)
}
export default NotFoundPage;